import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import axios from "../utilities/axios";

const initialState = {
  botConfigs: [],
  botConfigId: null,
  botIsEnabled: null,
  status: "idle",
  error: null,
  statusCreateBotConfig: "idle",
  errorCreateBotConfig: null,
};

export const fetchBotConfig = createAsyncThunk(
  "bot/fetchBotConfig",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(`/websites/${websiteID}/bot-config`);
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addBotConfig = createAsyncThunk(
  "bot/addBotConfig",
  async (values, thunkAPI) => {
    const { websiteID, days, ...rest } = values;

    const body = days.map((el) => {
      return {
        day: {
          ...el.day,
          workInterval: el.day.workInterval.map((interval) => {
            const newInterval = {
              ...interval,
              startDate:
                interval.startDate?.$d instanceof Date
                  ? dayjs(interval.startDate).format("HH:mm")
                  : interval.startDate,
              endDate:
                interval.endDate?.$d instanceof Date
                  ? dayjs(interval.endDate).format("HH:mm")
                  : interval.endDate,
            };
            const { _id, ...rest } = newInterval;
            return rest;
          }),
        },
      };
    });
    let data;
    try {
      const response = await axios.post(`/websites/${websiteID}/bot-config`, {
        days: body,
        enabled: true,
      });
      data = response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchBotConfig(websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateBotConfig = createAsyncThunk(
  "bot/updateBotConfig",
  async (values, thunkAPI) => {
    const { websiteID, days, botConfigId, ...rest } = values;

    const body = days.map((el) => {
      return {
        day: {
          ...el.day,
          workInterval: el.day.workInterval.map((interval) => {
            const newInterval = {
              ...interval,
              startDate:
                interval.startDate?.$d instanceof Date
                  ? dayjs(interval.startDate).format("HH:mm")
                  : interval.startDate,
              endDate:
                interval.endDate?.$d instanceof Date
                  ? dayjs(interval.endDate).format("HH:mm")
                  : interval.endDate,
            };
            const { _id, ...rest } = newInterval;
            return rest;
          }),
        },
      };
    });
    let data;
    try {
      const response = await axios.put(
        `/websites/${websiteID}/bot-config/${botConfigId}`,
        { days: body }
      );
      data = response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchBotConfig(websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateBotConfigActivation = createAsyncThunk(
  "bot/updateBotConfigActivation",
  async (values, thunkAPI) => {
    const { websiteID, enabled, botConfigId } = values;

    let data;
    try {
      const response = await axios.put(
        `/websites/${websiteID}/bot-config/${botConfigId}`,
        { enabled }
      );
      data = response.data;
      if (response.status === 200) {
        // thunkAPI.dispatch(fetchBotConfig(websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createBotConfigActivation = createAsyncThunk(
  "bot/createBotConfigActivation",
  async (values, thunkAPI) => {
    const { websiteID, enabled } = values;

    let data;
    try {
      const response = await axios.post(`/websites/${websiteID}/bot-config`, {
        enabled,
      });
      data = response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchBotConfig(websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "bot",
  initialState,
  reducers: {},
  extraReducers: {
    [addBotConfig.pending]: (state) => {
      state.statusCreateBotConfig = "loading";
    },
    [fetchBotConfig.pending]: (state) => {
      state.status = "loading";
    },
    [fetchBotConfig.fulfilled]: (state, action) => {
      state.status = "success";
      state.botConfigs = action.payload.days;
      state.botIsEnabled = action.payload.enabled;
      state.botConfigId = action.payload._id;
    },
    [fetchBotConfig.rejected]: (state) => {
      state.status = "failed";
    },
  },
});
export const { setNewAvatar, emptyAvatar } = slice.actions;
export const reducer = slice.reducer;
export default slice;
